<template>
  <div :id="props.targetId"></div>
</template>

<script>
import { onMounted, ref } from "vue";
import { token } from "@/utilities/token";
export default {
  components: {},
  props: {
    instrument: {
      type: Number,
      required: true,
    },
    targetId: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      default: null,
    },
    pricetype: {
      type: String,
      default: "price",
    },
    lastpriceDecimals: {
      type: Number,
      default: 2,
    },
  },
  setup(props) {
    MillistreamWidgetSettings.language = "sv";
    MillistreamWidgetSettings.token = token;
    const buildWidgets = () => {
      new Milli_Indicator_Mini({
        instrument: props.instrument,
        target: document.getElementById(props.targetId),
        name: props.name,
        pricetype: props.pricetype,
        num_lastdecimals: props.lastpriceDecimals,
      });
    };
    onMounted(() => {
      buildWidgets();
    });

    return { props };
  },
};
</script>
<style lang="scss">
@import "@/assets/scss/variables.scss";
.indicator-container {
  #usd-indicator .millistream-indicator-mini-diff::after,
  #euro-indicator .millistream-indicator-mini-diff::after,
  #gbp-indicator .millistream-indicator-mini-diff::after,
  #brent-indicator .millistream-indicator-mini-diff::after,
  #guld-indicator .millistream-indicator-mini-diff::after,
  #btc-indicator-mobile .millistream-indicator-mini-diff::after,
  #btc-indicator-desktop .millistream-indicator-mini-diff::after {
    content: "%";
  }
  #usd-indicator .millistream-indicator-mini-lastprice::after,
  #euro-indicator .millistream-indicator-mini-lastprice::after,
  #gbp-indicator .millistream-indicator-mini-lastprice::after {
    content: " kr";
  }
  #brent-indicator .millistream-indicator-mini-lastprice::after,
  #guld-indicator .millistream-indicator-mini-lastprice::after,
  #btc-indicator-mobile .millistream-indicator-mini-lastprice::after,
  #btc-indicator-desktop .millistream-indicator-mini-lastprice::after {
    content: " $";
  }
  #intrest-3m-indicator .millistream-indicator-mini-lastprice::after,
  #intrest-5y-indicator .millistream-indicator-mini-lastprice::after,
  #intrest-10y-indicator .millistream-indicator-mini-lastprice::after {
    content: " %";
  }

  #brent-indicator .millistream-indicator-mini-diff::after,
  #guld-indicator .millistream-indicator-mini-diff::after,
  #btc-indicator-mobile .millistream-indicator-mini-diff::after,
  #btc-indicator-desktop .millistream-indicator-mini-diff::after {
    content: "%";
  }

  .millistream-indicator-mini-main-div {
    .millistream-indicator-mini-name-div {
      text-align: left;
      label {
        font-family: SuecaSans;
        font-style: normal;
        font-weight: bold;
        font-size: 10px;
        line-height: 12px;
        /* identical to box height */
        color: rgba(37, 45, 64, 0.5);
      }
    }
    .millistream-indicator-mini-body-div {
      display: flex;
      flex-direction: column;
      text-align: left;
      gap: 4px;
      .millistream-indicator-mini-lastprice {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        font-family: Sueca Hd;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        color: $footer;
      }
      .millistream-indicator-mini-diff {
        background: rgba(37, 45, 64, 0.04);
        border-radius: 28px;
        padding: 6px 0;
        font-size: 12px;
        line-height: 14px;
        width: 70px;
        font-weight: 600;
        text-align: center;
        background-color: #fffcf8;
      }
    }
  }
}

@media (min-width: 781px) {
}
@media (max-width: 781px) {
}
</style>
