<template>
  <transition name="fade">
    <div v-show="props.show" class="box" :id="props.settings.targetId"></div>
  </transition>
</template>
<script>
import { onMounted } from "vue";
import { token } from "@/utilities/token";
export default {
  props: {
    settings: {
      type: Object,
      targetId: {
        type: String,
        required: true,
      },
      instrument: {
        type: Number,
        required: true,
      },
      flagClass: {
        type: String,
        required: true,
      },
      custom_name: {
        type: String,
      },
    },
    show: {
      type: Boolean,
    },
  },
  setup(props) {
    MillistreamWidgetSettings.language = "sv";
    MillistreamWidgetSettings.token = token;
    const buildWidgets = () => {
      new Milli_Indicator_Mini({
        instrument: props.settings.instrument,
        name: props.settings.custom_name,
        target: document.getElementById(props.settings.targetId),
        flag: props.settings.flagClass,
      });
    };
    onMounted(() => {
      buildWidgets();
    });

    return { props };
  },
};
</script>
<style lang="scss">
@import "@/assets/scss/variables.scss";

#map {
  div.box {
    display: inline-block;
  }
  div.millistream-indicator-mini-main-div {
    background: #fffcf8;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.16);
    border-radius: 4px;
    padding: 8px;
    font-family: Sueca Hd;
    div.millistream-indicator-mini-name-div {
      display: flex;
      label {
        margin-left: 28px;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        color: $excerpt;
      }
    }
    div.millistream-indicator-mini-body-div {
      margin-left: 28px;
      display: flex;
      justify-content: flex-start;
      gap: 0.5rem;
      .millistream-indicator-mini-diff::after {
        content: "%";
      }
      span {
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 14px;
        color: #888888;
      }
    }
  }
}
.millistream-flag-kanada {
  position: absolute;
  top: 11px;
  display: block;
  width: 20px;
  height: 20px;
  background: url("~@/assets/images/kanada.png");
}
.millistream-flag-usa {
  position: absolute;
  top: 11px;
  display: block;
  width: 20px;
  height: 20px;
  background: url("~@/assets/images/usa.png");
}
.millistream-flag-mexiko {
  position: absolute;
  top: 11px;
  display: block;
  width: 20px;
  height: 20px;
  background: url("~@/assets/images/mexiko.png");
}
/*.millistream-flag-brasilien {
    position: absolute;
    top: 11px;
    display: block;
    width: 20px;
    height: 20px;
    background: url("~@/assets/images/brasilien.png");
}*/
.millistream-flag-storbritanien {
  position: absolute;
  top: 11px;
  display: block;
  width: 20px;
  height: 20px;
  background: url("~@/assets/images/storbritanien.png");
}
.millistream-flag-tyskland {
  position: absolute;
  top: 11px;
  display: block;
  width: 20px;
  height: 20px;
  background: url("~@/assets/images/tyskland.png");
}
.millistream-flag-sydafrika {
  position: absolute;
  top: 11px;
  display: block;
  width: 20px;
  height: 20px;
  background: url("~@/assets/images/sydafrika.png");
}
.millistream-flag-ryssland {
  position: absolute;
  top: 11px;
  display: block;
  width: 20px;
  height: 20px;
  background: url("~@/assets/images/ryssland.png");
}
.millistream-flag-japan {
  position: absolute;
  top: 11px;
  display: block;
  width: 20px;
  height: 20px;
  background: url("~@/assets/images/japan.png");
}
.millistream-flag-sydkorea {
  position: absolute;
  top: 11px;
  display: block;
  width: 20px;
  height: 20px;
  background: url("~@/assets/images/sydkorea.png");
}
.millistream-flag-kina {
  position: absolute;
  top: 11px;
  display: block;
  width: 20px;
  height: 20px;
  background: url("~@/assets/images/kina.png");
}
.millistream-flag-australien {
  position: absolute;
  top: 11px;
  display: block;
  width: 20px;
  height: 20px;
  background: url("~@/assets/images/australien.png");
}
@media (min-width: 781px) {
}

@media (max-width: 781px) {
}
</style>
