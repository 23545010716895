<template>
  <div class="thermo-container">
    <div class="left-column">
      <h3>Börstemperaturen</h3>
      <h2 v-if="omxpi.diff > 0">Stockholmsbörsen är upp {{ omxpi.diff }}%</h2>
      <h2 v-else-if="omxpi.diff < 0">
        Stockholmsbörsen är ned {{ omxpi.diff }}%
      </h2>
      <h2 v-else>Stockholmsbörsen är oförändrad {{ omxpi.diff }}%</h2>
      <h3>Klockan {{ omxpi.time }}</h3>
      <div id="thermometer"></div>
      <div id="test"></div>
    </div>
    <div id="doughnut-container">
      <svg width="112" height="112" viewBox="0 0 42 42" class="donut">
        <circle
          class="donut-hole"
          :cx="cx"
          :cy="cy"
          :r="radius"
          fill="transparent"
        />
        <circle
          class="donut-ring"
          :cx="cx"
          :cy="cy"
          :r="radius"
          fill="transparent"
          stroke="transparent"
          :stroke-width="width"
        />
        <circle
          v-for="(elem, index) in list"
          :key="elem"
          class="donut-segment"
          :cx="cx"
          :cy="cy"
          :r="radius"
          fill="transparent"
          :stroke="elem.color"
          :stroke-width="width"
          :stroke-dasharray="dashArray"
          :stroke-dashoffset="dashOffset(elem.amount)"
          :transform="doughnutRotate(index)"
        />
      </svg>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted, reactive } from "vue";
import { token } from "@/utilities/token";
export default {
  components: {},
  setup(props) {
    MillistreamWidgetSettings.language = "sv";
    MillistreamWidgetSettings.token = token;
    const omxpi = reactive({
      diff: "",
      time: "",
    });
    const list = reactive([]);
    let filled = 0;
    let chartData = [];
    const startAngle = ref(-90);
    const radius = 15.91549430918954;
    const cy = 21;
    const cx = 21;
    const width = 10;
    const dashArray = 2 * Math.PI * radius;
    const angle = computed(() => {
      return (filled * 360) / 100 + startAngle.value;
    });
    const dataPercentage = (num) => {
      return parseInt(num) / dataTotal.value;
    };
    const dashOffset = (num) => {
      num = parseInt(num);
      const strokeDiff = dataPercentage(num) * dashArray;
      return dashArray - strokeDiff;
    };
    const dataTotal = computed(() => {
      return list.reduce((acc, item) => acc + parseInt(item.amount), 0);
    });
    const calculateChartData = () => {
      list.forEach((element, index) => {
        const data = {
          degrees: startAngle.value,
        };
        chartData.push(data);
        startAngle.value =
          dataPercentage(element.amount) * 360 + startAngle.value;
      });
    };
    const doughnutRotate = (index) => {
      return `rotate(${chartData[index].degrees}, ${cx}, ${cy})`;
    };
    const buildThermo = () => {
      new Milli_Thermometer({
        target: document.getElementById("thermometer"),
        list: [35206],
        legendpercent: false,
        legendposition: "bottom",
        onreadyCallback: function (e) {
          list.push({
            amount: e.children[0].textContent.substr(4).slice(0, -2),
            color: "#27B16F",
          });
          list.push({
            amount: e.children[1].textContent.substr(6).slice(0, -2),
            color: "#888888",
          });
          list.push({
            amount: e.children[2].textContent.substr(4).slice(0, -2),
            color: "#E2507A",
          });
          calculateChartData();
        },
      });
      new Milli_List({
        target: document.getElementById("test"),
        instrument: [6486],
        instrumenttype: null,
        controlcolumn: 4,
        stylecolumn: [4],
        num_decimals: 2,
        forcedecimals: true,
        tr_data_insref: true,
        fields: ["time", "diff1dprc"],
        timeformat: "HH:mm",
        link_field: ["insref"],
        onreadyCallback: () => {
          let diff = document
            .getElementById("test")
            .querySelector("[data-insref='6486']")
            .querySelector(".millistream-diff1dprc").textContent;
          omxpi.time = document
            .getElementById("test")
            .querySelector("[data-insref='6486']")
            .querySelector(".millistream-time").textContent;
          diff = diff.replace(",", ".");
          diff = parseFloat(diff).toFixed(2);
          omxpi.diff = diff;
        },
      });
    };
    onMounted(() => {
      buildThermo();
    });
    return {
      cy,
      cx,
      omxpi,
      radius,
      width,
      dashArray,
      dashOffset,
      doughnutRotate,
      list,
    };
  },
};
</script>
<style lang="scss">
@import "@/assets/scss/variables.scss";

#test {
  display: none;
}

#thermo {
  .thermo-container {
    padding: 12px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    h2 {
      font-family: Sueca Hd;
      font-size: 24px;
      line-height: 29px;
    }

    .left-column {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      text-align: left;

      .millistream-thermometer {
        display: none;
      }
    }

    svg {
      box-shadow: 0px 0px 24px rgb(0 0 0 / 6%);
      border-radius: 100%;
    }
  }

  .millistream-thermometer-legend {
    align-self: center;
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    gap: 0.5rem;

    &:after {
      content: "Antal bolag:";
      font-family: SuecaSans;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: $excerpt;
    }

    .millistream-thermometer-legend-div {
      font-family: SuecaSans;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: $labels;

      &:first-child {
        color: $green;
      }

      &:last-child {
        color: $red;
      }
    }
  }

  .millistream-thermometer-plus {
    height: 10px;
    background-color: $green;
  }

  .millistream-thermometer-unchanged {
    height: 10px;
    background-color: #888888;
  }

  .millistream-thermometer-minus {
    height: 10px;
    background-color: $red;
  }
}

@media (min-width: 781px) {
  #thermo {
    .thermo-container {
      #doughnut-container {
        display: grid;
        place-items: center;
      }
    }
  }
}

@media (max-width: 781px) {
  #thermo {
    .thermo-container {
      #doughnut-container {
        display: grid;
        align-content: flex-end;
      }

      .left-column {
        gap: 0.5rem;

        h2 {
          font-size: 20px;
          line-height: 24px;
        }
      }
    }

    .millistream-thermometer-legend {
      align-self: center;
      display: flex;
      flex-direction: column-reverse;
      justify-content: flex-end;
      gap: 4px;

      .millistream-thermometer-legend-div {
        font-family: SuecaSans;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: $labels;

        &:first-child {
          color: $green;
        }

        &:last-child {
          color: $red;
        }
      }
    }

    .millistream-thermometer-plus {
      height: 10px;
      background-color: $green;
    }

    .millistream-thermometer-unchanged {
      height: 10px;
      background-color: #888888;
    }

    .millistream-thermometer-minus {
      height: 10px;
      background-color: $red;
    }
  }
}
</style>
